<template>
  <div class="bg-final-01">
    <div
        class="container flex lg:flex-row flex-col gap-10 lg:gap-0 lg:justify-between pb-10 lg:pb-25 pt-10 md:pt-19.5 lg:gap-x-5"
    >
      <nuxt-link to="/" class="w-56 h-max transform -translate-y-4 md:block hidden">
        <v-image class="w-full h-full object-contain" :width="288" option_key="logo.global"></v-image>
      </nuxt-link>
      <div class="flex md:flex-row flex-col md:gap-10 flex-wrap">
        <GlobalNavFooter
            v-for="item in dataRender"
            :title="item?.name"
            :listNav="item?.childs"
        ></GlobalNavFooter>
      </div>
      <div class="flex flex-col gap-4.5">
        <div class="flex flex-col gap-2.5">
          <p class="text-15px font-medium text-black leading-6 leading-6">
            {{ $t('CUSTOMER_CARE') }}
          </p>
          <a :href="`mailto:${links_global?.customer_care_email}`" class="flex gap-1 group">
            <span class="i-custom-mail w-4.5 h-4.5 text-primary mt-1"></span>
            <span
                class="text-15px leading-6 text-bw-05 flex-1 break-all group-hover:text-primary duration-200"
            >
                            {{ links_global?.customer_care_email }}
                        </span>
          </a>
          <a :href="`tel:${links_global?.hotline}`" class="flex gap-1 group">
            <span class="i-custom-mobile-phone w-4.5 h-4.5 text-primary mt-1"></span>
            <p class="text-15px leading-6 text-bw-05 flex-1 group-hover:text-primary duration-200">
              {{ links_global?.hotline }}
            </p>
          </a>
        </div>
        <div class="flex flex-col gap-2.5">
          <p class="text-15px font-medium text-black leading-6">
            {{ $t('BUSINESS_COOPERATION') }}
          </p>
          <a :href="`mailto:${links_global?.cooperation_email}`" class="flex gap-1 group">
            <span class="i-custom-mail w-4.5 h-4.5 text-primary mt-1"></span>
            <p class="text-15px leading-6 text-bw-05 flex-1 group-hover:text-primary duration-200">
              {{ links_global?.cooperation_email }}
            </p>
          </a>
        </div>
      </div>
    </div>
    <div
        class="flex lg:items-center container lg:flex-row flex-col justify-between py-5 border-t border-black/10 lg:gap-5"
    >
      <div
          class="gap-x-6.5 gap-y-1 lg:gap-y-0 lg:gap-x-2.5 flex-wrap text-13px leading-6 font-medium uppercase lg:flex hidden"
      >
        <nuxt-link :to="privacy_policy" class="text-black/30 hover:text-black/60 duration-300 ont-medium">
          {{ $t('POLICY') }}
        </nuxt-link>
        <nuxt-link :to="terms_condition" class="text-black/30 hover:text-black/60 duration-300 font-medium">
          {{ $t('TERMS') }}
        </nuxt-link>
        <!--                <a href="" class="text-black/30 hover:text-black/60 duration-300 ont-medium">-->
        <!--                    {{ $t('COOKIE_OPTIONS') }}-->
        <!--                </a>-->
        <p class="text-black/30 font-medium capitalize">COPYRIGHT IZIon24© 2021 - {{ currentYear }}</p>
      </div>
      <div class="flex gap-4 sm:flex-row flex-col lg:justify-center mb-7.5 lg:mb-0">
        <p class="font-medium text-13px text-black/30 md:block hidden w-max">
          {{ $t('SOCIAL_NETWORK') }}
        </p>
        <div class="flex gap-2.5">
          <a
              v-if="links_global?.facebook"
              :href="links_global?.facebook"
              @click="useEventTracking(SOCIAL_EVENT_ID, SOCIAL_FACEBOOK_KEY)"
              class="i-custom-facebook w-5 h-5 text-primary hover:text-accent-01 duration-400"
          >
          </a>

          <a v-if="links_global?.zalo"
             :href="links_global?.zalo"
             @click="useEventTracking(SOCIAL_EVENT_ID, SOCIAL_ZALO_KEY)">
            <IconsZalo class="w-5 h-5 text-primary hover:text-accent-01 duration-400"></IconsZalo>
          </a>

          <a
              v-if="links_global?.youtube"
              :href="links_global?.youtube"
              @click="useEventTracking(SOCIAL_EVENT_ID, SOCIAL_YOUTUBE_KEY)"
              class="i-custom-youtube w-5 h-5 text-primary hover:text-accent-01 duration-400"
          >
          </a>

          <a
              v-if="links_global?.tiktok"
              :href="links_global?.tiktok"
              @click="useEventTracking(SOCIAL_EVENT_ID, SOCIAL_TIKTOK_KEY)"
              class="i-custom-tiktok w-5 h-5 text-primary hover:text-accent-01 duration-400"
          >
          </a>
        </div>
      </div>
      <div class="gap-1 flex">
        <a :href="links_global?.appstore"
           @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_APP_STORE_KEY)"
           target="_blank"
           class="sm:w-37.5 aspect-38/12">
          <img src="/images/global/appstore.svg" alt="appstore" class="w-full h-full object-contain"/>
        </a>
        <a :href="links_global?.google_play"
           @click="useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY)"
           target="_blank"
           class="sm:w-37.5 aspect-38/12">
          <img src="/images/global/googlePlay.svg" alt="googleplay" class="w-full h-full object-contain"/>
        </a>
      </div>

      <div class="mt-6 flex flex-col lg:hidden">
        <p class="text-black/30 text-13px font-semibold capitalize">
          COPYRIGHT IZIon24© 2021 - {{ currentYear }}
        </p>

        <div class="flex text-13px leading-6 font-semibold flex-wrap items-center">
          <nuxt-link :to="privacy_policy" class="text-black/30 hover:text-black/60 duration-300">
            {{ $t('POLICY') }}
          </nuxt-link>
          <span class="h-10px w-1px bg-black/20 mx-1"></span>
          <nuxt-link :to="terms_condition" class="text-black/30 hover:text-black/60 duration-300">
            {{ $t('TERMS') }}
          </nuxt-link>
          <span class="h-10px w-1px bg-black/20 mx-1"></span>
          <!--                    <a href="" class="text-black/30 hover:text-black/60 duration-300">-->
          <!--                        {{ $t('COOKIE_OPTIONS') }}-->
          <!--                    </a>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useAppGlobalData} from '../../composables'
import {
  DOWNLOAD_EVENT_ID,
  DOWNLOAD_APP_ON_APP_STORE_KEY,
  DOWNLOAD_APP_ON_GOOGLE_PLAY_KEY,
  SOCIAL_EVENT_ID,
  SOCIAL_FACEBOOK_KEY,
  SOCIAL_ZALO_KEY,
  SOCIAL_YOUTUBE_KEY,
  SOCIAL_TIKTOK_KEY,
  useEventTracking
} from '../../composables/ackee/event'

const {getDataFooterMenu} = useAppGlobalData()
const is_edit = inject('is_edit')
const dataRender = ref(null)

const currentDate = new Date()
const currentYear = currentDate.getFullYear()

const links_global = inject('globalSettings')?.links[0]

const privacy_policy = ref()
const terms_condition = ref()

const routesRender = async () => {
  links_global?.translations.map((item) => {
    if (item?.languages_code == useRoute().params.langCode) {
      privacy_policy.value = item?.privacy_policy
      terms_condition.value = item?.terms_condition
    }
  })
  dataRender.value = await getDataFooterMenu(useRoute().params.langCode)
}
watch(
    () => useRoute().params.langCode,
    () => {
      routesRender()
    }
)

onMounted(() => {
  routesRender()
})
</script>

<style></style>
